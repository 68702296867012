<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :title_modal="title_modal"
        :is-modal="isModal"
        :tombol_hide="true"
        :is-delete="isDelete"
      />
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      title:'Daftar '+this.$route.meta.pageTitle,
      title_modal: this.$route.meta.pageTitle,
      isDelete:false,
      dataurl:'/reminder-type',
      baseroute:'operasional-reminder',
      isModal:true,
      fields:[
        { key: 'name', label: 'Nama', type:'input', rules:'', placeholder:'Nama', disabled: true, sortable: true},
        { key: 'interval', label: 'Interval', type:'input', rules:'required', placeholder:'Interval', sortable: true},
      ],
    }
  },
}
</script>